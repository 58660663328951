input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

#otp {
  border: 1px solid #6E6E6E;
  border-radius: 12px; 
  font-size: 54px;
  min-width: 60px;
  min-height: 40px;
  margin-right: 12px;
}

.radioLabel {
  display: flex;
  align-items: center;
}
.radioInput {
  height: 18px;
  width: 18px;
  margin-top: 0;
}
